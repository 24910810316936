<template>
  <div
    class="d-flex align-center justify-between filter-title"
    @click="submitForm"
    type="button"
    >
    <div class="d-flex align-center flex-grow-1">
      <v-icon left color="primary">
        verified
      </v-icon>

      Only verified accounts

      <v-switch
        :value="isFilterApplied"
        color="primary"
        class="ml-3 mt-0"
        hide-details
      ></v-switch>
    </div>
  </div>
</template>

<script>
// used throughout the code
const filterName = "verified"

export default {
  name: "FilterVerified",

  data: () => ({
    form: false
  }),

  computed: {
    appliedFilter() {
      return this.$store.getters["influencerDiscovery/findFilterByType"](filterName)
    },

    // Used to bold the filter name text
    isFilterApplied() {
      return Boolean(this.appliedFilter)
    }
  },

  methods: {
    /**
     * Push the data from either of the inputs to the filters array in Vuex Store
     */
    async submitForm() {
      if (this.isFilterApplied) {
        this.removeFilterItem()
      }
      else {
        this.addFilterItem()
      }
    },

    addFilterItem() {
      this.$store.dispatch("influencerDiscovery/addFilter", {
        type: filterName,
        data: {
          color: "blue lighten-5",
          icon: "verified",
          iconColor: "blue",
          text: "Only Verified Accounts",
          inputs: {}
        }
      })
    },

    /**
     * Delete the entry from vuex store
     *
     * @param {Object} item
     */
    removeFilterItem() {
      this.$store.dispatch("influencerDiscovery/removeFilter", this.appliedFilter.id)
    },
  },
}
</script>
